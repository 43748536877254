import React from 'react';
import { Grid as MuiGrid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import TeamCard from '../cards/teamCard';
import Section from './section';

const TeamSection = styled(Section)`
  padding: 0 2rem 4rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0 0 4rem 0;
  }
`;

const Grid = styled(MuiGrid)`
  padding: 1rem 0.5rem;
`;

const Team = ({ team }) => (
  <TeamSection maxWidth="lg">
    <MuiGrid item container>
      <MuiGrid item component={Typography} variant="h2">
        {team.heading == null ? 'Our Team' : team.heading}
      </MuiGrid>
      <MuiGrid item xs={12} component={Typography} variant="h5" />
      <MuiGrid item container>
        {team.teamList &&
          team.teamList.map((teamMember, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TeamCard small teamMember={teamMember} />
            </Grid>
          ))}
      </MuiGrid>
    </MuiGrid>
  </TeamSection>
);
export default Team;
