import React from 'react';
import { Typography } from '@material-ui/core';
import PortableText from '@sanity/block-content-to-react';
import {
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
} from './card';

const TeamCard = ({ teamMember }) => (
  <Card smallcard>
    <CardActionArea
      link={teamMember.slug == null ? '' : `${teamMember.slug.current}/#bio`}
    >
      <CardMedia
        fluidImage={teamMember.image.asset.fluid}
        objectPosition="50% 20%"
      />
      <CardHeader
        title={
          <Typography variant="h5">
            {teamMember.fullname == null ? '' : teamMember.fullname}
          </Typography>
        }
        subTitle={
          <Typography>
            {teamMember.title == null ? '' : teamMember.title}{' '}
          </Typography>
        }
      />
      <CardContent>
        <Typography
          variant="body1"
          align="left"
          component={PortableText}
          blocks={teamMember._rawBio == null ? '' : teamMember._rawBio}
        />
      </CardContent>
    </CardActionArea>
    <CardActions
      link={teamMember.slug == null ? '' : `${teamMember.slug.current}/#bio`}
    >
      Read More
    </CardActions>
  </Card>
);
export default TeamCard;
