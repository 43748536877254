import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import { Typography, Grid as MuiGrid } from '@material-ui/core';
import PortableText from '@sanity/block-content-to-react';
import Section from './section';

const Grid = styled(MuiGrid)`
  padding: 0.5rem;
`;
const BodyGrid = styled(MuiGrid)`
  height: 100%;
`;

const Image = styled(Img)`
  border-radius: 5px;
  width: 100%;
`;

const BodySection = styled(Section)`
  padding: 4rem 2rem 2rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 4rem 0 2rem 0;
  }
`;

const Body = ({ id, heading, subheading, content, image }) => (
  <BodySection maxWidth="lg" id={id}>
    <MuiGrid container>
      {image && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Image fluid={image.asset.fluid} />
        </Grid>
      )}

      {content && (
        <BodyGrid item xs container>
          <MuiGrid item xs={12} component={Typography} variant="h2">
            {heading && heading}
          </MuiGrid>
          <MuiGrid item xs={12} component={Typography} variant="h5">
            {subheading == null ? '' : subheading}
          </MuiGrid>
          <Grid item xs={12}>
            <Typography
              component={PortableText}
              blocks={content && content}
              variant="body1"
            />
          </Grid>
        </BodyGrid>
      )}
    </MuiGrid>
  </BodySection>
);
export default Body;
