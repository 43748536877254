import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Reviews from '../components/sections/reviews';
import Request from '../components/sections/request';
import Awards from '../components/sections/awards';
import Quote from '../components/sections/quote';
import Body from '../components/sections/body';
import Team from '../components/sections/team';
import SEO from '../components/seo';

const AboutPage = () => {
  const pageData = useStaticQuery(graphql`
    {
      sanityAboutPage {
        name
        heading
        image {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawBodySection
        bodySection {
          heading
        }
        teamSection {
          heading
          subheading
          teamList {
            fullname
            title
            _rawBio
            slug {
              current
            }
            image {
              asset {
                url
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        reviewSection {
          heading
          subheading
          googleReviewsToggle
          reviewList {
            name
            location
            reviewText
            image {
              asset {
                fluid(maxWidth: 150) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        awardSection {
          awardList {
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        requestFormToggle
        quoteToggle
      }
    }
  `);

  const page = pageData.sanityAboutPage;

  return (
    <Layout heroButton heroHeading={page.heading} heroImage={page.image}>
      <SEO title="About Us" />
      <Body
        heading={
          page.bodySection.heading == null
            ? 'Our History'
            : page.bodySection.heading
        }
        content={page._rawBodySection.text}
      />
      <Team team={page.teamSection} />
      {/* Reviews */}
      <Reviews reviews={page.reviewSection} />
      {/* Request Form */}
      {page.requestFormToggle && <Request />}
      {/* Awards */}
      <Awards awards={page.awardSection} />
      {/* Quote */}
      {page.quoteToggle && <Quote />}
    </Layout>
  );
};

export default AboutPage;
